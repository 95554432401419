import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { UserInputWrapper, UserInputLabel, UserInputError } from './elements';
import {
  $gray,
  $secondaryFont,
  $gold,
} from '../../../styles';

const StyledInput = styled.input`
  border: solid 1px ${$gray};
  font-size: 1rem;
  font-family: ${$secondaryFont};
  line-height: 1rem;
  padding: 0.75rem 1rem;
  border-radius: 4px;
  width: 100%;
  &:focus {
    outline-color: ${$gold};
  }
`;

const UserTextInput = (props) => {
  const {
    placeholder,
    disabled,
    error,
    label,
    control,
    name,
    rules,
    className,
    onChange,
    defaultValue,
    type,
  } = props;
  const isRequired = rules && !!rules.required;
  return (
    <UserInputWrapper>
      { label && <UserInputLabel required={isRequired}>{ label }</UserInputLabel> }
      <Controller
        control={control}
        name={name}
        rules={rules}
        defaultValue={defaultValue}
        render={({ onChange: controllerOnChange, value }) => (
          <StyledInput
            placeholder={placeholder}
            className={className}
            value={value}
            onChange={(selected) => {
              controllerOnChange(selected);
              if (onChange) {
                onChange(selected);
              }
            }}
            disabled={disabled}
            type={type}
            autoComplete="off"
            autoCorrect="off"
            spellCheck="off"
            autoCapitalize="off"
          />
        )}
      />
      { error && <UserInputError>{ error.message }</UserInputError> }
    </UserInputWrapper>
  );
};

UserTextInput.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  rules: PropTypes.shape({
    required: PropTypes.string,
  }),
  onChange: PropTypes.func,
  control: PropTypes.shape({}).isRequired, // Provided by react-hook-form, passed in via parent
  defaultValue: PropTypes.string,
  type: PropTypes.string,
};

UserTextInput.defaultProps = {
  disabled: false,
  placeholder: null,
  className: null,
  error: null,
  label: null,
  rules: null,
  onChange: null,
  defaultValue: '',
  type: 'text',
};

export const UncontrolledUserTextInput = (props) => {
  const {
    placeholder,
    disabled,
    label,
    className,
    onChange,
    type,
    error,
  } = props;
  return (
    <UserInputWrapper>
      { label && <UserInputLabel>{ label }</UserInputLabel> }
      <StyledInput
        placeholder={placeholder}
        className={className}
        onChange={onChange}
        disabled={disabled}
        type={type}
        autoComplete="off"
        autoCorrect="off"
        spellCheck="off"
        autoCapitalize="off"
      />
      { error && <UserInputError>{ error }</UserInputError> }
    </UserInputWrapper>
  );
};

UncontrolledUserTextInput.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  control: PropTypes.shape({}).isRequired, // Provided by react-hook-form, passed in via parent
  type: PropTypes.string,
};

UncontrolledUserTextInput.defaultProps = {
  disabled: false,
  placeholder: null,
  className: null,
  error: null,
  label: null,
  onChange: null,
  type: 'text',
};

export default UserTextInput;
