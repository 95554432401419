import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Drawer } from '@mui/material';
import OutsideClickHandler from 'react-outside-click-handler';
import styled from 'styled-components';
import {
  MdMenu,
  MdClose,
  MdKeyboardArrowDown,
} from 'react-icons/md';
import {
  FiLogOut,
  FiUser,
  FiCalendar,
} from 'react-icons/fi';
import { BiWallet } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { backendUrl } from '../../utils/backend';
import Wrapper from '../common/Wrapper';
import WalletBalance from '../common/WalletBalance';
import Logo from '../common/Logo';
import Button from '../common/Button';
import {
  $black,
  $secondaryFont,
  $gray,
  $white,
  $standardShadow,
  $medium,
  $copyBlack,
} from '../../styles';

const LEAD_CAPTURE_PATH = '/custom/celebrity-bookings';

const NavBackground = styled.div`
  background: ${$black};
  position: fixed;
  width: 100%;
  z-index: 5;
`;

const VerticalDivider = styled.div`
  width: 1px;
  height: 1.25rem;
  background: ${$white};
  margin: 0 1rem;
`;

const HorizontaDivider = styled.div`
  height: 1px;
  background: #b3b3b3;
  margin: 2rem 1.5rem;
`;

const TransitionBackground = styled.div`
  position: fixed;
  width: 100%;
  z-index: 5;
  background: ${({ background }) => background};
  transition: background 0.15s;
  .register-button {
    background: transparent;
  }
  ${VerticalDivider} {
    background: ${$white};
  }
`;

const NavContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  font-family: ${$secondaryFont};
  font-weight: ${$medium};
  &:hover {
    color: ${$white};
  }
  transition: color 0.1s;
  svg {
    margin-right: 0.5rem;
  }
  color: #b3b3b3;
  margin: 0;
  padding: 1rem 1.5rem;
  font-size: 1rem;
  @media screen and (min-width: 960px) {
    margin: 0 0.75rem;
    padding: 0.5rem;
    color: ${$white};
  }
`;

const StyledNavAnchor = styled.a`
  display: flex;
  align-items: center;
  font-family: ${$secondaryFont};
  font-weight: ${$medium};
  &:hover {
    color: ${$white};
  }
  transition: color 0.1s;
  svg {
    margin-right: 0.5rem;
  }
  color: #b3b3b3;
  margin: 0;
  padding: 1rem 1.5rem;
  font-size: 1rem;
  @media screen and (min-width: 960px) {
    margin: 0 1rem;
    padding: 0.5rem;
    color: ${$white};
  }
`;

const NavItems = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const DesktopNavItems = styled.div`
  margin: 0 -1rem;
  display: none;
  @media screen and (min-width: 960px) {
    display: flex;
    align-items: center;
  }
`;

const StyledAnchor = styled.a`
  display: flex;
  align-items: center;
  font-family: ${$secondaryFont};
  font-weight: ${$medium};
  &:hover {
    color: ${$white};
  }
  transition: color 0.1s;
  svg {
    margin-right: 0.5rem;
  }
  color: #b3b3b3;
  margin: 0;
  padding: 1rem 1.5rem;
  font-size: 1rem;
  @media screen and (min-width: 960px) {
    margin: 0 1rem;
    padding: 0.5rem;
    color: ${$white};
  }
`;

const Hamburger = styled(MdMenu)`
  color: ${$white};
  font-size: 2rem;
  margin-left: 1rem;
  cursor: pointer;
  @media screen and (min-width: 960px) {
    display: none;
  }
`;

const Cta = styled(Button)`
  margin: 0 1rem;
  @media screen and (min-width: 960px) {
    margin: 0 0 0 2rem;
  }
`;

const CloseNavContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 1.5rem;
  height: 5rem;
  > div {
    svg {
      height: 2rem;
      width: 2rem;
      color: #b3b3b3;
    }
  }
`;

const ProfileButton = styled.div`
  display none;
  @media screen and (min-width: 960px) {
    display: flex;
    cursor: pointer;
    margin-left: 1.5rem;
    align-items: center;
    padding: 0.5rem;
    svg {
      color: ${$white};
      &:first-child {
        height: 1.25rem;
        width: 1.25rem;
        margin-right: 0.25rem;
      }
      &:last-child {
        display: block;
        height: 1.25rem;
        width: 1.25rem;
      }
    }
  }
`;

const AdminButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin: 0 1rem;
  padding: 0.5rem;
  color: ${$white};
  font-weight: ${$medium};
  svg {
    margin-left: 0.5rem;
    height: 1.25rem;
    width: 1.25rem;
  }
`;

const ProfileDropdownContainer = styled.div`
  max-height: ${({ height }) => height};
  transition: max-height 0.15s;
  overflow: hidden;
  position: absolute;
  top: calc(100% + 1rem);
  background: ${$white};
  border-radius: 0.5rem;
  box-shadow: ${$standardShadow};
  min-width: 14.75rem;
`;

const ProfileDropdownInner = styled.div`
  padding: 1rem 1.5rem;
`;

const ProfileDropdownLink = styled(Link)`
  display: flex;
  align-items: center;
  font-family: ${$secondaryFont};
  color: ${$black};
  &:hover {
    color: ${$black};
  }
  svg {
    margin-right: 0.5rem;
  }
  margin: 0;
  padding: 0.5rem 0;
  font-size: 1rem;
`;

const AdminDropdownLink = styled(Link)`
  display: flex;
  align-items: center;
  font-family: ${$secondaryFont};
  font-weight: ${$medium};
  color: ${$copyBlack};
  &:hover {
    color: ${$black};
  }
  svg {
    margin-right: 0.5rem;
  }
  margin: 0;
  padding: 1rem 0;
  font-size: 1rem;
  &:not(:last-child) {
    border-bottom: solid 1px ${$gray};
  }
`;

const ProfileWalletContainer = styled.div`
  padding: 0.75rem 0;
  border-top: solid 1px ${$gray};
  border-bottom: solid 1px ${$gray};
  margin: 0.5rem 0;
`;

const ProfileDropdownAnchor = styled.a`
  display: flex;
  align-items: center;
  font-family: ${$secondaryFont};
  color: ${$black};
  &:hover {
    color: ${$black};
  }
  svg {
    margin-right: 0.5rem;
  }
  margin: 0;
  padding: 0.5rem 0;
  font-size: 1rem;
`;

const TransparentNavBackground = (props) => {
  const { children } = props;
  const [isTop, setIsTop] = useState(true);

  // Check to see if the user is scrolled to top on intial mount
  useEffect(() => {
    if (window.scrollY !== 0) {
      setIsTop(false);
    }
  }, []);

  // Check to see if the user is scrolled to top on subsequent scrolls
  useEffect(() => {
    const onScroll = () => {
      const scrolledToTop = window.scrollY === 0;
      // Only need to set state if 'isTop' status has changed
      if (scrolledToTop !== isTop) {
        setIsTop(scrolledToTop);
      }
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [isTop]);

  return (
    <TransitionBackground background={isTop ? 'transparent' : $black}>
      { children }
    </TransitionBackground>
  );
};

TransparentNavBackground.propTypes = {
  children: PropTypes.node.isRequired,
};

const NavDrawer = (props) => {
  const {
    open,
    onClose,
    children,
  } = props;
  return (
    <Drawer anchor="right" open={open} onClose={onClose} className="nav-drawer">
      { children }
    </Drawer>
  );
};

NavDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

const NavItem = (props) => {
  const {
    to,
    href,
    children,
    onClick,
    target,
  } = props;
  if (href) {
    return (
      <StyledNavAnchor
        href={href}
        onClick={onClick}
        target={target}
      >
        { children }
      </StyledNavAnchor>
    );
  }
  return (
    <StyledNavLink
      to={to}
      activeClassName="active-nav-item"
      onClick={onClick}
      target={target}
    >
      { children }
    </StyledNavLink>
  );
};

NavItem.propTypes = {
  href: PropTypes.string,
  to: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  target: PropTypes.string,
};

NavItem.defaultProps = {
  to: null,
  href: null,
  onClick: null,
  target: null,
};

const PrimaryCta = () => (
  <Cta
    to={LEAD_CAPTURE_PATH}
    theme="whiteOutline"
    margin="0 1rem"
    className="register-button"
  >
    BOOK A SPEAKER
  </Cta>
);

const GuestNav = () => {
  const [showDrawer, setShowDrawer] = useState(false);
  const closeDrawer = () => setShowDrawer(false);
  const NavItemWithClose = (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <NavItem {...props} onClick={closeDrawer} />
  );
  return (
    <>
      <NavItems>
        <DesktopNavItems>
          <NavItem to="/our-work">Our Work</NavItem>
          <NavItem to="/custom">Custom Events</NavItem>
          <NavItem to="/premium-hospitality">Premium Hospitality</NavItem>
          <NavItem to="/login">Corporate Accounts</NavItem>
        </DesktopNavItems>
        <PrimaryCta />
      </NavItems>
      <Hamburger onClick={() => setShowDrawer(true)} />
      <NavDrawer open={showDrawer} onClose={closeDrawer}>
        <CloseNavContainer>
          <div onClick={closeDrawer}>
            <MdClose />
          </div>
        </CloseNavContainer>
        <NavItemWithClose to="/">Home</NavItemWithClose>
        <NavItemWithClose to="/our-work">Our Work</NavItemWithClose>
        <NavItemWithClose to="/custom">Custom Events</NavItemWithClose>
        <NavItemWithClose to="/premium-hospitality">Premium Hospitality</NavItemWithClose>
        <HorizontaDivider />
        <NavItemWithClose to="/login">
          <FiUser />
          Corporate Accounts
        </NavItemWithClose>
      </NavDrawer>
    </>
  );
};

const AdminDropdown = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const closeDropdown = () => setShowDropdown(false);
  const toggleDropdown = () => setShowDropdown((prev) => !prev);
  let dropdownHeight = 0;
  if (showDropdown) {
    dropdownHeight = '18rem';
  }
  return (
    <OutsideClickHandler onOutsideClick={closeDropdown}>
      <AdminButton onClick={toggleDropdown}>
        Admin
        <MdKeyboardArrowDown />
      </AdminButton>
      <ProfileDropdownContainer height={dropdownHeight}>
        <ProfileDropdownInner onClick={toggleDropdown}>
          <AdminDropdownLink to="/admin/member-events">
            Member Events
          </AdminDropdownLink>
          <AdminDropdownLink to="/admin/users">
            Users
          </AdminDropdownLink>
          <AdminDropdownLink to="/admin/talent">
            Talent
          </AdminDropdownLink>
          <AdminDropdownLink to="/admin/venues">
            Venues
          </AdminDropdownLink>
          <AdminDropdownLink to="/admin/vivid-seats">
            Vivid Seats
          </AdminDropdownLink>
        </ProfileDropdownInner>
      </ProfileDropdownContainer>
    </OutsideClickHandler>
  );
};

const ProfileDropdown = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const closeDropdown = () => setShowDropdown(false);
  const toggleDropdown = () => setShowDropdown((prev) => !prev);
  let dropdownHeight = 0;
  if (showDropdown) {
    dropdownHeight = '18rem';
  }
  const logoutUrl = backendUrl('/logout');
  return (
    <OutsideClickHandler onOutsideClick={closeDropdown}>
      <ProfileButton onClick={toggleDropdown}>
        <FiUser />
        <MdKeyboardArrowDown />
      </ProfileButton>
      <ProfileDropdownContainer height={dropdownHeight}>
        <ProfileDropdownInner onClick={toggleDropdown}>
          <ProfileDropdownLink to="/profile">
            <FiUser />
            Profile
          </ProfileDropdownLink>
          <ProfileDropdownLink to="/my-events">
            <FiCalendar />
            My Events
          </ProfileDropdownLink>
          <ProfileWalletContainer>
            <WalletBalance />
          </ProfileWalletContainer>
          <ProfileDropdownAnchor href={logoutUrl}>
            <FiLogOut />
            Logout
          </ProfileDropdownAnchor>
        </ProfileDropdownInner>
      </ProfileDropdownContainer>
    </OutsideClickHandler>
  );
};

const UserNav = () => {
  const { user } = useSelector((state) => state.user);
  const { isAdmin } = user;
  const [showDrawer, setShowDrawer] = useState(false);
  const closeDrawer = () => setShowDrawer(false);
  const logoutUrl = backendUrl('/logout');
  const NavItemWithClose = (props) => (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <NavItem {...props} onClick={closeDrawer} />
  );
  return (
    <>
      <NavItems>
        <DesktopNavItems>
          <NavItem to="/our-work">Our Work</NavItem>
          <NavItem to="/custom">Custom Events</NavItem>
          <NavItem to="/premium-hospitality">Premium Hospitality</NavItem>
          {
            isAdmin && <AdminDropdown />
          }
          <VerticalDivider />
        </DesktopNavItems>
        <ProfileDropdown />
        <PrimaryCta />
      </NavItems>
      <Hamburger onClick={() => setShowDrawer(true)} />
      <NavDrawer open={showDrawer} onClose={closeDrawer}>
        <CloseNavContainer>
          <div onClick={closeDrawer}>
            <MdClose />
          </div>
        </CloseNavContainer>
        <NavItemWithClose to="/our-work">Our Work</NavItemWithClose>
        <NavItemWithClose to="/custom">Custom Events</NavItemWithClose>
        <NavItemWithClose to="/premium-hospitality">Premium Hospitality</NavItemWithClose>
        {
          isAdmin && (
            <>
              <HorizontaDivider />
              <NavItemWithClose to="/admin/member-events">Member Events Admin</NavItemWithClose>
              <NavItemWithClose to="/admin/users">Users Admin</NavItemWithClose>
              <NavItemWithClose to="/admin/vivid-seats">Vivid Seats Admin</NavItemWithClose>
            </>
          )
        }
        <HorizontaDivider />
        <NavItemWithClose to="/profile">
          <FiUser />
          Profile
        </NavItemWithClose>
        <NavItemWithClose to="/my-events">
          <FiCalendar />
          My Events
        </NavItemWithClose>
        <NavItemWithClose to="/wallet">
          <BiWallet />
          Wallet
        </NavItemWithClose>
        <StyledAnchor href={logoutUrl}>
          <FiLogOut />
          Log out
        </StyledAnchor>
      </NavDrawer>
    </>
  );
};

const NavBar = ({ transparent }) => {
  const { authenticated } = useSelector((state) => state.user);
  const BackgroundContainer = transparent ? TransparentNavBackground : NavBackground;
  let Nav;
  if (!authenticated) {
    Nav = GuestNav;
  } else {
    Nav = UserNav;
  }
  return (
    <BackgroundContainer>
      <Wrapper padding="1.375rem 2rem">
        <NavContent>
          <Link to="/">
            <Logo />
          </Link>
          <Nav />
        </NavContent>
      </Wrapper>
    </BackgroundContainer>
  );
};

NavBar.propTypes = {
  transparent: PropTypes.bool,
};

NavBar.defaultProps = {
  transparent: false,
};

export default NavBar;
