import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { initializeLogRocket, identifyLogRocketUser } from '../../analytics';

const LogRocket = ({ disabled, children }) => {
  const { user } = useSelector((state) => ({
    app: state.app,
    user: state.user.user,
  }));

  useEffect(() => {
    if (!disabled) {
      initializeLogRocket();
    }
  }, [disabled]);

  useEffect(() => {
    if (user.id && !disabled) {
      const {
        id,
        email,
        givenNames,
        familyName,
      } = user;

      const fullName = `${givenNames} ${familyName}`;

      // Identify registered users by their ID
      identifyLogRocketUser({ id, name: fullName, email });
    }
  }, [user, disabled]);

  return children;
};

LogRocket.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

export default LogRocket;
