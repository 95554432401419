import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { logo } from '../../imageUrls';

const StyledLogo = styled.div`
  img {
    height: 100%;
    height: ${({ size }) => size};
  }
`;

const Logo = ({ to, size, className }) => (
  <StyledLogo size={size} className={className}>
    {
      to ?
        <Link to={to}><img src={logo} alt="Thuzio logo" /></Link>
        : <img src={logo} alt="Thuzio logo" />
    }
  </StyledLogo>
);

Logo.propTypes = {
  size: PropTypes.string,
  className: PropTypes.string,
  to: PropTypes.string,
};

Logo.defaultProps = {
  size: '2.75rem',
  className: null,
  to: null,
};

export default Logo;
