import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const maxWidths = {
  xxsmall: '375px',
  xsmall: '576px',
  small: '768px',
  medium: '960px',
  large: '1200px',
  xlarge: '1440px',
  xxlarge: '1680px',
  auto: 'auto',
};

const Wrapper = styled.div`
  padding: ${({ padding }) => padding};
  max-width: ${({ size }) => maxWidths[size]};
  margin: auto;
  height: ${({ autoHeight }) => (autoHeight ? 'auto' : '100%')}
`;

const WrapperComponent = ({
  className, children, size, padding, autoHeight, id,
}) => (
  <Wrapper
    className={className}
    size={size}
    padding={padding}
    autoHeight={autoHeight}
    id={id}
  >
    { children }
  </Wrapper>
);

WrapperComponent.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.oneOf([
    'xxlarge',
    'xlarge',
    'large',
    'medium',
    'small',
    'xsmall',
    'xxsmall',
  ]),
  padding: PropTypes.string,
  autoHeight: PropTypes.bool,
};

WrapperComponent.defaultProps = {
  className: null,
  size: 'large',
  padding: '2rem',
  children: null,
  autoHeight: false,
  id: null,
};

export default WrapperComponent;
