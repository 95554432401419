import qs from 'qs';
import axios from 'axios';

export const backendUrl = (path) => `${process.env.REACT_APP_BACKEND_URL}${path}`;

const ingestResponse = async (res) => res.data;

export const get = async ({ path, params }) => {
  const url = `${backendUrl(path)}/${params ? `?${qs.stringify(params)}` : ''}`;
  const response = await axios.get(url, {
    withCredentials: true,
  });
  return ingestResponse(response);
};

export const post = async ({ path, body }) => {
  const url = backendUrl(path);
  const response = await axios.post(url, body, {
    withCredentials: true,
  });
  return ingestResponse(response);
};
