import styled from 'styled-components';
import {
  $standardShadow,
  $darkShadow,
  $primaryFont,
  $secondaryFont,
  $medium,
  $red,
  $white,
  $black,
  $copyBlack,
} from '../../styles';

/*
  Simple elements shared between components without additional react/redux logic.
*/

export const FullViewportContainer = styled.div`
  width: 100vw;
`;

export const Subtitle = styled.div`
  font-size: 1.25rem;
  font-family: ${({ secondaryFont }) => (secondaryFont ? $secondaryFont : $primaryFont)};
  margin: ${({ margin }) => margin || '0 0 1rem 0'};
  text-transform: ${({ secondaryFont }) => (secondaryFont ? null : 'uppercase')};
`;

export const Card = styled.div`
  padding: 1rem;
  box-shadow: ${$standardShadow};
  background: ${({ background }) => background || $white};
  ${({ disableHover }) => (disableHover ? null : `  
    &:hover {
      box-shadow: ${$darkShadow};
    }
  `)}
  margin: ${({ margin }) => margin || '0'};
  position: ${({ position }) => position || 'static'};
`;

export const RelativeContainer = styled.div`
  position: relative;
`;

export const MaxWidthContainer = styled.div`
  max-width: ${({ maxWidth }) => maxWidth || '48rem'};
  margin: auto;
`;

export const ArchivedSpan = styled.span`
  color: ${$red};
`;

// Beelow used since starting user-facing

export const Heading = styled.h1`
  font-size: 2.5rem;
  // line-height: 2.75rem;
  font-family: ${({ secondaryFont }) => (secondaryFont ? $secondaryFont : $primaryFont)};
  margin: ${({ margin }) => margin || '0 0 1rem 0'};
  color: ${({ color }) => (color ? `${color} !important` : $black)};
  font-weight: ${$medium};
`;

export const HeadingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  ${Heading} {
    margin-right: 2rem;
  }
`;

export const Title = styled.h2`
  font-size: 2rem;
  font-family: ${({ secondaryFont }) => (secondaryFont ? $secondaryFont : $primaryFont)};
  margin: ${({ margin }) => margin || '0 0 1rem 0'};
  color: ${({ color }) => (color ? `${color} !important` : $black)};
  font-weight: ${$medium};
`;

export const Copy = styled.p`
  margin: ${({ margin }) => margin || '0'};
  color: ${({ color }) => `${color}!important` || $copyBlack};
  font-size: 1rem;
  line-height: 1.5rem;
`;
