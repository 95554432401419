import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import Loading from '../components/common/Loading';
import Portal from '../components/common/Portal';
import { FullViewportContainer } from '../components/common/elements';

const OverlayContainer = styled(FullViewportContainer)`
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 2000; // Ant design modals have a detail z-index of 1000
  width: 100%;
  height: 100%;
  // min-height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
`;

export default () => {
  // Initialize the app by loading user data into redux
  const { initializing, loading } = useSelector((state) => state.app);
  if (initializing || loading) {
    return (
      <Portal>
        <OverlayContainer>
          <Loading centerSelf overlay />
        </OverlayContainer>
      </Portal>
    );
  }

  return null;
};
