export function GqlError({ name, errors }) {
  this.message = (errors[0] && errors[0].message) || 'An unexpected error occured.';
  this.name = name || 'Gql Error';
  this.errors = errors;
  this.errorCode = (errors[0] && errors[0].errorCode);
}

export const formatErrors = (err) => {
  const {
    graphQLErrors,
    networkError,
    errors,
  } = err;

  if (graphQLErrors && graphQLErrors.length > 0) {
    return graphQLErrors;
  }

  if (networkError) {
    if (networkError.result) {
      return networkError.result.errors;
    }
    return networkError;
  }

  if (errors) {
    return errors;
  }

  return [{
    message: err,
  }];
};
