import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

const GTAG_IDS = {
  suite: 'AW-876134052/ABboCIvXgPMCEKT94qED',
  long: 'AW-876134052/hw8GCLvBkYIDEKT94qED',
  'celebrity-bookings': 'AW-876134052/Jeg8CK2u8OsBEKT94qED',
  'culinary-events': 'AW-876134052/6W42CP_DgOwBEKT94qED',
  'corporate-events': 'AW-876134052/87stCOCo8OsBEKT94qED',
  'speaker-bookings': 'AW-876134052/pA9fCJKTuPUCEKT94qED',
  'holiday-party': 'AW-876134052/_4jfCInl8fUCEKT94qED',
};

const { REACT_APP_LOGROCKET_ID, REACT_APP_ENABLE_LOGROCKET } = process.env;

const logRocketEnabled = REACT_APP_ENABLE_LOGROCKET === 'TRUE';

export const reportGtagConversion = (type) => {
  if (window.gtag_report_conversion) {
    const id = GTAG_IDS[type];
    window.gtag_report_conversion(id);
  }
};

export const initializeLogRocket = () => {
  if (!logRocketEnabled) {
    return;
  }
  LogRocket.init(REACT_APP_LOGROCKET_ID);
  setupLogRocketReact(LogRocket);
};

export const identifyLogRocketUser = ({ id, name, email }) => {
  if (!logRocketEnabled) {
    return;
  }
  LogRocket.identify(id, { name, email });
};
