import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { loading } from '../../imageUrls';

const Container = styled.div`
  ${({ centerSelf }) => centerSelf && `
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
  ${({ overlay }) => overlay && `
    background: rgba(13, 13, 13, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  `};
`;

const ImageContainer = styled.div`
  margin: ${({ margin }) => margin};
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  img {
    height: 100%;
    width: 100%;
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    0% { 
      transform: rotate(0); 
    }
    100% { 
      transform: rotate(360deg);
    }
  }
`;

const Loading = ({
  margin, centerSelf, size, overlay,
}) => (
  <Container centerSelf={centerSelf} overlay={overlay}>
    <ImageContainer margin={margin} size={size}>
      <img src={loading} alt="loading" />
    </ImageContainer>
  </Container>
);

Loading.propTypes = {
  margin: PropTypes.string,
  size: PropTypes.string,
  centerSelf: PropTypes.bool,
  overlay: PropTypes.bool,
};

Loading.defaultProps = {
  margin: '0',
  size: '5rem',
  centerSelf: false,
  overlay: false,
};

export default Loading;
