import { createSlice } from '@reduxjs/toolkit';

const INITIAL_LONG_LEAD_CAPTURE_FORM_STATE = {
  stagedRequest: null,
  completedRequest: null,
};

const longLeadCaptureFormSlice = createSlice({
  name: 'app',
  initialState: INITIAL_LONG_LEAD_CAPTURE_FORM_STATE,
  reducers: {
    setCompletedRequest: (state, { payload }) => {
      state.completedRequest = payload;
    },
    reset: () => INITIAL_LONG_LEAD_CAPTURE_FORM_STATE,
  },
});

export default longLeadCaptureFormSlice.reducer;

export const {
  setCompletedRequest,
  reset,
} = longLeadCaptureFormSlice.actions;
