import * as EmailValidator from 'email-validator';

export const validateEmail = (email) => {
  if (EmailValidator.validate(String(email))) {
    return true;
  }
  return false;
};

export const validatePassword = (password) => {
  if (!password) return false;
  if (password.length < 8) return 'Passwords must contain at least 8 characters';
  if (!/[0-9]/.test(password)) return 'Passwords must contain at least one number';
  if (!/[a-z]/i.test(password)) return 'Passwords must contain at least one letter';
  return true;
};
