import 'core-js/features/symbol/index';
import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';
import GlobalStyle from './app/GlobalStyle';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <>
    <GlobalStyle />
    <App />
  </>,
  document.getElementById('root'),
);

reportWebVitals();
