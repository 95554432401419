import numeral from 'numeral';
import Big from 'big.js';

export const formatNum = (num) => numeral(num).format('0,0[.]00');
export const formatCurrency = (num) => numeral(num).format('$0,0[.]00');

// to deal with javascript decimal precision issue
export const addDecimals = (val1, val2) => +(Big(val1).plus(Big(val2)));
export const subtractDecimals = (val1, val2) => +(Big(val1).sub(Big(val2)));
export const multiplyDecimals = (val1, val2) => +(Big(val1).times(Big(val2)));
export const divideDecimals = (val1, val2) => +(Big(val1).div(Big(val2)));

export const calculateCartTotalsCents = ({ cart, taxRate }) => {
  let cartSubtotalCents = 0;

  const cartWithTotals = cart.map((item) => {
    const { ticketType, quantity } = item;
    const subtotalCents = ticketType.priceCents * quantity;
    cartSubtotalCents += subtotalCents;
    return {
      ...item,
      subtotalCents,
    };
  });

  const taxCents = Math.round(multiplyDecimals(cartSubtotalCents, +taxRate));
  const totalCents = cartSubtotalCents + taxCents;

  return {
    cartWithTotals,
    cartSubtotalCents,
    taxCents,
    totalCents,
  };
};

export const calculateCartTotalsCredits = ({ cart }) => {
  let totalCredits = 0;

  const cartWithTotals = cart.map((item) => {
    const { ticketType, quantity } = item;
    const subtotalCredits = ticketType.priceCredits * quantity;
    totalCredits += subtotalCredits;
    return {
      ...item,
      subtotalCredits,
    };
  });

  return {
    cartWithTotals,
    cartSubtotalCredits: totalCredits,
    taxCredits: 0,
    totalCredits,
  };
};

export const calculateRedemptionTotalsCredits = ({ creditRedemptionItems }) => {
  let totalCredits = 0;

  const redemptionItemsWithTotals = creditRedemptionItems.map((item) => {
    const { quantity, priceCredits } = item;
    const subtotalCredits = priceCredits * quantity;
    totalCredits += subtotalCredits;
    return {
      ...item,
      subtotalCredits,
    };
  });

  return {
    redemptionItemsWithTotals,
    cartSubtotalCredits: totalCredits,
    taxCredits: 0,
    totalCredits,
  };
};

export const calculatePurchaseTotalsCents = ({ purchaseItems, taxRate }) => {
  let cartSubtotalCents = 0;

  const purchaseItemsWithTotals = purchaseItems.map((item) => {
    const { quantity, priceCents } = item;
    const subtotalCents = priceCents * quantity;
    cartSubtotalCents += subtotalCents;
    return {
      ...item,
      subtotalCents,
    };
  });

  const taxCents = Math.round(multiplyDecimals(cartSubtotalCents, +taxRate));
  const totalCents = cartSubtotalCents + taxCents;

  return {
    purchaseItemsWithTotals,
    cartSubtotalCents,
    taxCents,
    totalCents,
  };
};
