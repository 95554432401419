/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ReactRouterPropTypes from 'react-router-prop-types';
import { Route as ReactRouterRoute } from 'react-router';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import ScrollToTop from './ScrollToTop';
import Head from '../components/common/Head';
import NavBar from '../components/nav/NavBar';
import Footer from '../components/footer/Footer';
import AntdStyles from '../components/common/AntdStyles';
import LogRocket from '../components/common/LogRocket';
import AssumedUserFrame from './AssumedUserFrame';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  // height: 1px;
  // min-height: 100vh;
`;

const Content = styled.div`
  display: flex;
  flex: 1 1 auto;
  height: auto;
  min-height: calc(100vh - 5.5rem);
  margin: ${({ margin }) => margin || 0};
  > div {
    width: 100%;
    overflow: hidden;
    // height: 100%;
  }
`;

const NavlessContent = styled(Content)`
  min-height: 100vh;
`;

const Route = (props) => {
  const {
    loginRequired,
    guestOnly,
    adminOnly,
    disableNav,
    disableRecording,
    transparentNav,
    location,
    scrollToTop,
  } = props;
  const { authenticated, user } = useSelector((state) => state.user);
  const { isAdmin, isAssuming } = user;

  // Guest needs to login to see this route
  if (!authenticated && loginRequired) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: {
            referrer: `${location.pathname}${location.search}`,
          },
        }}
      />
    );
  }

  // Logged in user isn't allowed to acess this guest route (e.g. login or registration pages)
  if (guestOnly && authenticated) {
    return <Redirect to="/" />;
  }

  // Logged in user isn't allowed to acess this admin route
  if (adminOnly && !isAdmin) {
    return <Redirect to="/" />;
  }

  const Outer = isAssuming ? AssumedUserFrame : 'div';

  if (disableNav) {
    return (
      <Outer>
        {
          scrollToTop && <ScrollToTop />
        }
        <Head />
        <LogRocket disabled={disableRecording}>
          <Container>
            <NavlessContent>
              <ReactRouterRoute {...props} />
            </NavlessContent>
          </Container>
        </LogRocket>
      </Outer>
    );
  }

  const margin = transparentNav ? '0' : '5.5rem 0 0 0';
  return (
    <Outer>
      {
        scrollToTop && <ScrollToTop />
      }
      <Head />
      <LogRocket disabled={disableRecording}>
        <Container>
          {
            // Import antd css for admin UI only
            adminOnly && <AntdStyles />
          }
          <NavBar transparent={transparentNav} />
          {
            transparentNav ? (
              <NavlessContent margin={margin}>
                <ReactRouterRoute {...props} />
              </NavlessContent>
            ) : (
              <Content margin={margin}>
                <ReactRouterRoute {...props} />
              </Content>
            )
          }
          <Footer />
        </Container>
      </LogRocket>
    </Outer>
  );
};

Route.propTypes = {
  loginRequired: PropTypes.bool,
  guestOnly: PropTypes.bool,
  disableNav: PropTypes.bool,
  disableRecording: PropTypes.bool,
  adminOnly: PropTypes.bool,
  transparentNav: PropTypes.bool,
  location: ReactRouterPropTypes.location,
  scrollToTop: PropTypes.bool,
};

Route.defaultProps = {
  loginRequired: false,
  guestOnly: false,
  disableNav: false,
  disableRecording: false,
  adminOnly: false,
  transparentNav: false,
  location: null,
  scrollToTop: true,
};

export default Route;
