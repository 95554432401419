import React from 'react';
import { Provider } from 'react-redux';
import styled from 'styled-components';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import smoothscroll from 'smoothscroll-polyfill';
import { ApolloProvider } from '@apollo/client';
import { IntercomProvider } from 'react-use-intercom';
import apolloClient from '../apolloClient';
import Routes from './routes';
import store from '../redux';
import UserDataProvider from './UserDataProvider';
import LoadingOverlay from './LoadingOverlay';
import ContactModalGlobal from '../components/common/ContactModalGlobal';

const { REACT_APP_INTERCOM_APP_ID } = process.env;

const AppContainer = styled.div`
  position: relative;
`;

toast.configure();

// Required for smooth scrolling on safari
smoothscroll.polyfill();

const App = () => (
  <AppContainer id="App">
    <IntercomProvider appId={REACT_APP_INTERCOM_APP_ID}>
      <ApolloProvider client={apolloClient}>
        <Provider store={store}>
          <UserDataProvider>
            <Routes />
            <ContactModalGlobal />
          </UserDataProvider>
          <LoadingOverlay />
        </Provider>
      </ApolloProvider>
    </IntercomProvider>
    <ToastContainer />
  </AppContainer>
);

export default App;
