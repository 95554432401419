/*
  Useful stuff:
  http://marcodiiga.github.io/rgba-to-rgb-conversion <-- RGBA to RGB / HEX conversion
*/

// Brand typefaces
export const $primaryFont = 'Oswald, sans-serif';
export const $secondaryFont = 'Fira Sans, sans-serif';

// Brand color palette
export const $black = '#000000';
export const $white = '#FFFFFF';
export const $steelBlue = '#667E91';
export const $gold = '#E5C282';
export const $robinBlue = '#50A3FC';

export const $copyBlack = '#666666'; // #000000 with 50% opacity over white
export const $lightRobinBlue = '#e6f6ff'; // #50A3FC with 50% opacity over white
export const $lightSteelBlue = '#d9dfe4'; // #667E91 with 25% opacity over white
export const $darkSteelBlue = '#204165'; // #667E91 with 40% opacity over black
export const $darkThemeCopyBackground = '#222333';

// For errors
export const $red = '#C5292A';
export const $lightRed = '#f9eaea'; // #C5292A with 10% opacity over white

// For borders, dividers, outlines, etc..
export const $gray = '#DDE2E5'; // to remove, used for borders
export const $standardShadow = '0 1px 3px 0 rgba(0,0,0,0.25)';
export const $darkShadow = '0 2px 4px 0 rgba(0,0,0,0.50)';
export const $dockShadow = '0 2px 8px 0 rgba(0,0,0,0.25);';

// To match the blue used in ant-design components
export const $antdBlue = '#40a9ff';

// For hovers, warnings, etc...
export const $lightGold = '#fcf9f3'; // #E5C282 with 10% opacity over white
export const $darkGold = '#ceaf75';
export const $placeholderGrey = 'rgb(117, 117, 117)';

// Only used in admin
export const $offWhite = '#F2F2F2'; // really just used as an admin background
export const $darkPrimary = '#121212'; // to remove, used in temp login/registration input
export const $green = '#33cc33';

// Font weights
export const $regular = 400;
export const $medium = 500;
export const $bold = 700;

export const NAVBAR_OFFSET = 5.5 * 16;
export const NAVBAR_OFFSET_REM = '5.5rem';

export const generateBackgroundImageOverlay = (url, overlayStrength = 0.6) => (`
  linear-gradient(
    rgba(0, 0, 0, ${overlayStrength}),
    rgba(0, 0, 0, ${overlayStrength})
  ),
  url(${url}),
  ${$black};
`);

export const generateRichTextStyle = (args = {}) => (`
  p {
    color: ${args.copyColor || $copyBlack};
    line-height: 2;

    // Best guess targeting first heading
    &:first-child {
      margin-top: 0;
      > span {
        &:only-child {
          > strong {
            &:only-child {
              color: ${args.headingColor || $black};
            }
          }
        }
      }
    }
  }
  a {
    color: ${$robinBlue};
  }
  ol, ul {
    margin: -0.5rem;
    li {
      margin: 0.5rem;
    }
  }
`);

export const styleTheme = {
  gold: {
    background: $gold,
    color: $black,
    hoverBackground: $gold,
  },
  blackOutline: {
    background: $white,
    color: $black,
    hoverBackground: $white,
    borderColor: $black,
  },
  whiteOutline: {
    background: $black,
    color: $white,
    hoverBackground: $black,
    borderColor: $white,
  },
  red: {
    background: $red,
    color: $white,
    hoverBackground: $red,
  },
  robinBlue: {
    background: $robinBlue,
    color: $white,
    hoverBackground: $robinBlue,
  },
  robinBlueOutline: {
    background: 'transparent',
    color: $robinBlue,
    hoverBackground: 'transparent',
    borderColor: $robinBlue,
  },
  steelBlue: {
    background: $steelBlue,
    color: $white,
    hoverBackground: $steelBlue,
  },
};
