import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import styled from 'styled-components';
import Button from 'antd/lib/button';

import { exitAssumeMode } from '../redux/user/userSlice';
import { $red } from '../styles';

const Outer = styled.div`
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${$red};
`;

const FrameTitle = styled.div`
  text-align: center;
  padding: 0.6rem;
  & > button {
    font-size: 13px;
  }
`;

const UserInfo = styled.span`
  font-weight: bold;
`;

const Inner = styled.div`
  position: fixed;
  top: 2.5rem;
  left: 0.5rem;
  right: 0.5rem;
  bottom: 0.5rem;
  overflow-y: auto;
`;

const AssumedUserFrame = ({ children }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const {
    givenNames,
    familyName,
    email,
  } = user;
  return (
    <Outer>
      <FrameTitle>
        Operating as
        &nbsp;
        <UserInfo>
          {`${givenNames} ${familyName} (${email})`}
        </UserInfo>
        &nbsp;
        <Button type="primary" size="small" onClick={() => dispatch(exitAssumeMode())}>
          Back to admin
        </Button>
      </FrameTitle>
      <Inner id="assumed-user-inner">
        { children }
      </Inner>
    </Outer>
  );
};

AssumedUserFrame.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AssumedUserFrame;
