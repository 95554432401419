import React from 'react';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import Input from 'react-phone-number-input/input';
import PropTypes from 'prop-types';
import { UserInputWrapper, UserInputLabel, UserInputError } from './elements';
import {
  $gray,
  $secondaryFont,
  $gold,
} from '../../../styles';

const StyledPhoneNumberInput = styled(Input)`
  border: solid 1px ${$gray};
  font-size: 1rem;
  font-family: ${$secondaryFont};
  line-height: 1rem;
  padding: 0.75rem 1rem;
  border-radius: 4px;
  width: 100%;
  &:focus {
    outline-color: ${$gold};
  }
`;

const UserPhoneNumberInput = (props) => {
  const {
    placeholder,
    disabled,
    error,
    label,
    control,
    name,
    rules,
    className,
    onChange,
  } = props;
  const isRequired = rules && !!rules.required;
  return (
    <UserInputWrapper error={error} className={className}>
      { label && <UserInputLabel required={isRequired}>{ label }</UserInputLabel> }
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={({ onChange: controllerOnChange, value }) => (
          <StyledPhoneNumberInput
            placeholder={placeholder}
            className={className}
            value={value}
            onChange={(selected) => {
              controllerOnChange(selected);
              if (onChange) {
                onChange(selected);
              }
            }}
            country="US"
            disabled={disabled}
            autoComplete="off"
            autoCorrect="off"
            spellCheck="off"
            autoCapitalize="off"
          />
        )}
      />
      { error && <UserInputError>{ error.message }</UserInputError> }
    </UserInputWrapper>
  );
};

UserPhoneNumberInput.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  rules: PropTypes.shape({
    required: PropTypes.string,
  }),
  onChange: PropTypes.func,
  control: PropTypes.shape({}).isRequired, // Provided by react-hook-form, passed in via parent
};

UserPhoneNumberInput.defaultProps = {
  disabled: false,
  placeholder: null,
  className: null,
  error: null,
  label: null,
  rules: null,
  onChange: null,
};

export default UserPhoneNumberInput;
