import React from 'react';
import PropTypes from 'prop-types';
import { MdErrorOutline } from 'react-icons/md';
import styled from 'styled-components';
import {
  $medium,
  $red,
  $secondaryFont,
  $lightRed,
} from '../../../styles';

export const UserInputWrapper = styled.div`

`;

export const UserInputRow = styled.div`
  display: flex;
  > div {
    flex: 1;
    margin: 0 0.5rem;
  }
  margin: ${({ margin }) => margin || '0 -0.5rem'};
`;

export const UserInputsContainer = styled.div`
  margin: ${({ margin }) => margin || '0'};
  ${UserInputRow} {
    &:not(:first-child) {
      margin-top: 1rem;
    }
  }
`;

export const UserInputLabel = styled.div`
  margin-bottom: 0.5rem;
  font-family: ${$secondaryFont};
  font-weight: ${$medium};
  font-size: 1rem;
`;

const ErrorContainer = styled.div`
  background: ${$lightRed};
  color: ${$red};
  border-radius: 0.5rem;
  font-size: 1rem;
  padding: 0.25rem 0.5rem;
  margin-top: 0.5rem;
  display: inline-flex;
  align-items: center;
  span {
    margin-left: 0.5rem;
  }
  svg {
    min-height: 1rem;
    min-width: 1rem;
  }
`;

export const UserInputError = ({ children, className }) => (
  <ErrorContainer className={className}>
    <MdErrorOutline />
    <span>
      { children }
    </span>
  </ErrorContainer>
);

UserInputError.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

UserInputError.defaultProps = {
  className: '',
};
