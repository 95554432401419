import { createSlice } from '@reduxjs/toolkit';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const INITIAL_APP_STATE = {
  loading: false,
  initializing: true,
  showContactModal: false,
  contactModalPrefillNote: '',
};

const appSlice = createSlice({
  name: 'app',
  initialState: INITIAL_APP_STATE,
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setInitializing: (state, { payload }) => {
      state.initializing = payload;
    },
    setShowContactModal: (state, { payload }) => {
      state.showContactModal = payload.show;
      state.contactModalPrefillNote = payload.contactModalPrefillNote || '';
    },
  },
});

export default appSlice.reducer;

export const setLoading = (loading) => {
  if (loading) {
    disableBodyScroll(document.querySelector('#portal-root'));
  } else {
    clearAllBodyScrollLocks();
  }
  return appSlice.actions.setLoading(loading);
};

export const { setInitializing, setShowContactModal } = appSlice.actions;

/* export const openContactModal = (payload) => {
  if (payload && payload.prefillNote) {
    appSlice.actions.setContactModalPrefillNote(payload.prefillNote);
  }
  return appSlice.actions.setShowContactModal(true);
};
 */

export const openContactModal = (contactModalPrefillNote) => appSlice.actions.setShowContactModal({
  show: true,
  contactModalPrefillNote,
});

export const closeContactModal = () => appSlice.actions.setShowContactModal({
  show: false,
  contactModalPrefillNote: '',
});

/* export const closeContactModal = () => {
  appSlice.actions.setContactModalPrefillNote('');
  return appSlice.actions.setShowContactModal(false);
}; */
