import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { MdClose } from 'react-icons/md';
import { $copyBlack } from '../../styles';

const IconContainer = styled.div`
  position: absolute;
  padding: 1rem 1rem 0.5rem 0.5rem;
  top: 0;
  right: 0;
  cursor: pointer;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledCloseIcon = styled(MdClose)`
  color: ${({ color }) => color || $copyBlack}}};
  height: 1.25rem;
  width: 1.25rem;
`;

const ModalCloseButton = ({ onClick, color }) => (
  <IconContainer onClick={onClick}>
    <IconWrapper>
      <StyledCloseIcon color={color} />
    </IconWrapper>
  </IconContainer>
);

ModalCloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string,
};

ModalCloseButton.defaultProps = {
  color: null,
};

export default ModalCloseButton;
