import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Dialog } from '@mui/material';

const Container = styled.div`
  position: relative;
  text-align: ${({ alignContents }) => alignContents};
  padding: 2rem;
`;

const UserModal = (props) => {
  const {
    open,
    onClose,
    children,
    className,
    alignContents,
  } = props;
  return (
    <Dialog
      onClose={onClose}
      open={open}
      className={className}
    >
      <Container alignContents={alignContents}>
        { children }
      </Container>
    </Dialog>
  );
};

UserModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  alignContents: PropTypes.string,
};

UserModal.defaultProps = {
  className: '',
  alignContents: 'center',
};

export default UserModal;
