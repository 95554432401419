import React from 'react';
import ReactDOM from 'react-dom';

//  We use a CDN here to dynamically import antd css. Allows us to load it only for admin components

const AntdStyles = () => ReactDOM.createPortal(
  (<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/antd/4.11.2/antd.min.css" type="text/css" />),
  document.head,
);

export default AntdStyles;
