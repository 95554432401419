import React, { useState, useEffect } from 'react';
import _some from 'lodash/some';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { useDispatch, useSelector } from 'react-redux';
import Button from './Button';
import UserTextInput from './user/UserTextInput';
import UserTextAreaInput from './user/UserTextAreaInput';
import UserPhoneNumberInput from './user/UserPhoneNumberInput';
import UserModal from './user/UserModal';
import ModalCloseButton from './ModalCloseButton';
import { contactRequestSubmittedIcon } from '../../imageUrls';
import { identifyLogRocketUser } from '../../analytics';
import { trimValues } from '../../utils/transformers';
import { setLoading, closeContactModal } from '../../redux/app/appSlice';
import useCreateRequest from '../../hooks/useCreateRequest';
import { validateEmail } from '../../utils/validators';
import { Copy, Title } from './elements';
import { UserInputsContainer, UserInputRow } from './user/elements';
import {
  $black,
  $white,
  $primaryFont,
  $medium,
  $gray,
} from '../../styles';

const ModalHeader = styled.div`
  margin: -2rem -2rem 0 -2rem;
  padding: 2rem;
  background: ${$black};
  color: ${$white};
  > div:first-child {
    font-family: ${$primaryFont};
    font-weight: ${$medium};
    font-size: 1.25rem;
  }
  > div:last-child {
    margin-top: 0.75rem;
  }
`;

const ModalBody = styled.div`
  textarea {
    resize: none;
  }
  @media screen and (max-width: 577px) {
    textarea {
      min-height: ${({ shrinkTextAreaOnMobile }) => (shrinkTextAreaOnMobile ? '4rem' : '5.5rem')}
    }
  }
  @media screen and (min-width: 576px) {
    width: 29rem;
  }
`;

const ButtonContainer = styled.div`
  text-align: center;
  button {
    width: 100%;
    max-width: 20rem;
  }
`;

const ContactInputRow = styled(UserInputRow)`
  @media screen and (max-width: 577px) {
    flex-direction: column;
    > div:not(:first-child) {
      margin-top: 1rem;
    }
  }
`;

const ContactConfirmationIcon = styled.img`
  width: 5rem;
  margin-top: 1rem;
`;

const ContactConfirmationContainer = styled.div`
  text-align: center;
  ${Copy}:last-child {
    border-top: solid 1px ${$gray};
    padding-top: 1.5rem;
    margin-top: 1.5rem;
  }
  padding-bottom: 1rem;
`;

const Bold = styled.span`
  font-weight: ${$medium};
`;

const ContactModal = () => {
  const dispatch = useDispatch();
  const { showContactModal, contactModalPrefillNote } = useSelector((state) => state.app);
  const [requestConfirmation, setRequestConfirmation] = useState(null);
  const [createRequest] = useCreateRequest();
  const {
    handleSubmit,
    errors,
    control,
    setValue,
  } = useForm({
    defaultValues: {
      givenNames: '',
      familyName: '',
      email: '',
      phoneNumber: '',
      additionalDetails: contactModalPrefillNote,
    },
  });

  useEffect(() => {
    /*
      Workaround for react-hook-form / MUI bug
      https://stackoverflow.com/questions/59526726/react-hook-forms-setvalue-method-is-not-working-if-input-is-in-material-ui-dial
    */
    if (contactModalPrefillNote) {
      setTimeout(() => {
        setValue('additionalDetails', contactModalPrefillNote);
      }, 1);
    } else {
      setTimeout(() => {
        setValue('additionalDetails', '');
      }, 200);
    }
  }, [showContactModal, setValue, contactModalPrefillNote]);

  const onSubmit = async (requestData) => {
    dispatch(setLoading(true));
    const newRequest = await createRequest({
      fields: trimValues(requestData),
      viaUrl: window.location.href,
      viaCompany: 'Thuzio 2.0',
      type: 'contact',
    });
    const criteria = JSON.parse(newRequest.criteria);
    setRequestConfirmation({
      ...newRequest,
      criteria,
    });
    // Identify leads by their email
    identifyLogRocketUser({
      id: newRequest.email,
      email: newRequest.email,
    });
    dispatch(setLoading(false));
  };

  const closeModal = () => {
    dispatch(closeContactModal());
    // Wait for exit animation before clearing confirmation
    setTimeout(() => setRequestConfirmation(null), 200);
  };

  const hasErrors = _some(Object.keys(errors).map((key) => !!errors[key]));

  return (
    <UserModal
      open={showContactModal}
      onClose={closeModal}
      alignContents="left"
      className="our-work-contact-modal"
    >
      {
        requestConfirmation ? (
          <ModalBody>
            <ModalCloseButton onClick={closeModal} />
            <ContactConfirmationContainer>
              <ContactConfirmationIcon src={contactRequestSubmittedIcon} />
              <Title margin>Success!</Title>
              <Copy>
                {`Thanks ${requestConfirmation.criteria.givenNames}, your request has been received.`}
              </Copy>
              <Copy>
                Our client success team has been notified and a representative will contact you at
                { ' ' }
                <Bold>{ requestConfirmation.email }</Bold>
                { ' ' }
                shortly to proceed.
              </Copy>
            </ContactConfirmationContainer>
          </ModalBody>
        ) : (
          <>
            <ModalHeader>
              <div>Contact Us</div>
              <ModalCloseButton onClick={closeModal} />
              <div>A Thuzio representative will be in touch within 48 hours.</div>
            </ModalHeader>
            <ModalBody shrinkTextAreaOnMobile={hasErrors}>
              <form>
                <UserInputsContainer margin="1.75rem 0 0 0">
                  <UserInputRow>
                    <UserTextInput
                      control={control}
                      placeholder="First name"
                      label="First name"
                      name="givenNames"
                      rules={{ required: 'Required' }}
                      error={errors.givenNames}
                      type="text"
                    />
                    <UserTextInput
                      control={control}
                      placeholder="Last name"
                      label="Last name"
                      name="familyName"
                      rules={{ required: 'Required' }}
                      error={errors.familyName}
                      type="text"
                    />
                  </UserInputRow>
                  <ContactInputRow>
                    <UserTextInput
                      control={control}
                      placeholder="email@domain.com"
                      label="Email address"
                      name="email"
                      rules={{
                        required: 'Required',
                        validate: (email) => {
                          if (validateEmail(email)) {
                            return true;
                          }
                          return 'Invalid email';
                        },
                      }}
                      error={errors.email}
                      type="email"
                    />
                    <UserPhoneNumberInput
                      control={control}
                      placeholder="Phone number"
                      label="Phone number"
                      name="phoneNumber"
                      rules={{
                        required: 'Required',
                        validate: (val) => {
                          if (val && !isPossiblePhoneNumber(val)) {
                            return 'Invalid phone number';
                          }
                          return true;
                        },
                      }}
                      error={errors.phoneNumber}
                    />
                  </ContactInputRow>
                  <UserInputRow>
                    <UserTextAreaInput
                      control={control}
                      placeholder="Questions, comments, event inquiries, etc..."
                      label="How can we help?"
                      name="additionalDetails"
                      error={errors.additionalDetails}
                    />
                  </UserInputRow>
                </UserInputsContainer>
                <ButtonContainer>
                  <Button margin="1.5rem auto 0 auto" onClick={handleSubmit(onSubmit)}>Submit</Button>
                </ButtonContainer>
              </form>
            </ModalBody>
          </>
        )
      }
    </UserModal>
  );
};

export default ContactModal;
