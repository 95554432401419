import { createGlobalStyle } from 'styled-components';
import {
  $secondaryFont,
  $primaryFont,
  $medium,
  $black,
} from '../styles';

// This is where we override antd default styling

export default createGlobalStyle`
  html {
    font-size: 14px;
    @media screen and (min-width: 768px) { // Everything larger than an iPad
      font-size: 16px;
    }
    body {
      font-family: ${$secondaryFont};
      line-height: normal !important;
      font-size: inherit;
    }
  }

  strong {
    font-weight: ${$medium};
  }

  // ANTD OVERRIDES

  a  {
    line-height: normal;
  }

  .ant-select-dropdown {
    z-index: 1000 !important;
  }

  .ant-modal-header {
    .ant-modal-title {
      font-family: ${$primaryFont};
      font-weight: ${$medium};
      font-size: 1.25rem;
    }
  }

  .ant-modal-body {
    font-size: 1rem;
  }

  .admin-ticket-more-options {
    .ant-popover-inner-content {
      padding: 0;
      .ant-menu-item {
        line-height: normal;
        height: auto;
        margin:0 !important;
        padding: 0.5rem 0.75rem;
        width: 100%;
        min-width: 7rem;
        &:active, &.ant-menu-item-selected, &:hover {
          background: inherit !important;
          color: inherit;
        }
        &:hover {
          &:not(.admin-ticket-option-disabled) {
            text-decoration: underline;
          }
        }
      }
    }
  }

  img {
    vertical-align: middle !important;
  }

  // MATERIAL-UI OVERRIDES
  .user-error-modal, .ticket-claim-confirmation-modal {
    .MuiPaper-root {
      max-width: 23rem;
    }
  }

  .MuiDialog-root {
    .MuiPaper-rounded {
      border-radius: 1rem;
    }
  }
  .nav-drawer {
    .MuiDrawer-paper {
      background: ${$black};
      min-width: 12rem;
    }
  }

  .suite-lead-capture-modal, .our-work-contact-modal {
    .MuiDialog-paperScrollPaper {
      max-height: calc(100% - 16px);
      margin: 1rem;
    }
  }

  .suite-lead-capture-modal {
    .MuiDialog-paperScrollPaper {
      max-width: 27rem;
    }
  }

  @media screen (min-width: 400px) {
    .suite-lead-capture-modal {
      .MuiDialog-paperScrollPaper {
        margin: 2rem;
      }
    }
  }

  @media screen (min-width: 768px) {
    .suite-lead-capture-modal {
      .MuiDialog-paperScrollPaper {
        max-width: 25rem;
      }
    }
  }

  .ticket-purchase-modal {
    .MuiPaper-root {
      width: 100%;
      height: 100%;
      max-height: unset;
      overflow-y: unset;
      margin: 0;
    }
    .MuiPaper-rounded {
      border-radius: 0;
    }
    @media screen and (min-width: 576px) {
      .MuiPaper-root {
        width: 36rem;
        overflow-y: unset;
        max-height: calc(100% - 64px);
        height: auto;
      }
      .MuiPaper-rounded {
        border-radius: 1rem;
      }
    }
  }
  
  .ticket-type-description-popover {
    .MuiPaper-root {
      border-radius: 0.5rem;
      max-width: 17rem;
      pointer-events: auto;
    }
  }

  .ticket-editor-modal {
    .MuiPaper-root {
      width: 28rem;
    }
  }

  .pac-container {
    z-index: 1301;
  }
`;
