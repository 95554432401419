import { ApolloClient, InMemoryCache } from '@apollo/client';
import 'react-toastify/dist/ReactToastify.css';

const gqlEndpoint = `${process.env.REACT_APP_BACKEND_URL}/graphql`;

const client = new ApolloClient({
  uri: gqlEndpoint,
  credentials: 'include',
  cache: new InMemoryCache(),
  fetch,
});

client.defaultOptions = {
  query: {
    fetchPolicy: 'network-only',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

export default client;
