import /* React,  */ { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserData } from '../redux/user/userSlice';
import { setInitializing } from '../redux/app/appSlice';
// import { RelativeContainer } from '../components/common/elements';

const UserDataProvider = ({ children }) => {
  // Initialize the app by loading user data into redux
  const { initializing } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchUserData());
      dispatch(setInitializing(false));
    };
    fetchData();
  }, [dispatch]);

  if (initializing) {
    return null;
  }

  return children;
};

UserDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserDataProvider;
