import React from 'react';
import PropTypes from 'prop-types';
import Theme from './Theme';

import {
  $black,
  $white,
  $copyBlack,
} from '../../styles';

const theme = {
  background: $white,
  copyColor: $copyBlack,
  headingColor: $black,
  inputTextColor: $black,
};

const UserTheme = ({ children, theme: propTheme }) => (
  <Theme theme={{ ...theme, ...propTheme }}>
    {children}
  </Theme>
);

UserTheme.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.shape({
    background: PropTypes.string,
    copyColor: PropTypes.string,
    headingColor: PropTypes.string,
    inputTextColor: PropTypes.string,
  }),
};

UserTheme.defaultProps = {
  theme: {},
};

export default UserTheme;
