import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
// import { FullViewportContainer } from '../common/elements';

// const BaseStyle = styled(FullViewportContainer)`
const BaseStyle = styled.div`
  background: ${({ theme }) => theme && theme.background};
  color: ${({ theme }) => theme && theme.copyColor};
  h1 {
    color: ${({ theme }) => theme && theme.headingColor};
  }
  a {
    color: ${({ theme }) => theme && theme.anchorColor};
    &:hover {
      ${({ theme }) => theme && theme.anchorColor};
    }
  }
  p {
    color: ${({ theme }) => theme && theme.copyColor};
  }
  input {
    color: ${({ theme }) => theme && theme.inputTextColor};
  }
`;

const Theme = ({ theme, children }) => (
  <ThemeProvider theme={theme}>
    <BaseStyle>
      {children}
    </BaseStyle>
  </ThemeProvider>
);

Theme.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.shape({
    background: PropTypes.string,
    copyColor: PropTypes.string,
    inputTextColor: PropTypes.string,
  }).isRequired,
};

export default Theme;
