import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  FiTwitter,
  FiInstagram,
  FiFacebook,
  FiYoutube,
  FiLinkedin,
} from 'react-icons/fi';
import Logo from '../common/Logo';
import Wrapper from '../common/Wrapper';
import UserTheme from '../theme/UserTheme';
import {
  $black,
  $white,
  $steelBlue,
} from '../../styles';
import { footerLockup } from '../../imageUrls';

const Background = styled.div`
  background: ${$black};
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: solid 1px ${$steelBlue};
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  color: ${$white};
`;

const DesktopFooter = styled(Content)`
  display: none;
  @media screen and (min-width: 576px) {
    display: flex;
  }
`;

const MobileFooter = styled(Content)`
  flex-direction: column;
  @media screen and (min-width: 576px) {
    display: none;
  }
`;

const Address = styled.div`
  > div {
    margin-bottom: 0.25rem;
  }
`;

const EmailLink = styled.a`
  color: ${$white};
  text-decoration none;
`;

const NavColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  a {
    &:not(:first-child) {
      margin-top: 1rem;
    }
    color: ${$white};
  }
  @media screen and (min-width: 576px) {
    margin: 0 auto;
  }
`;

const SocialRow = styled.div`
  display: flex;
  margin: 0.5rem -0.5rem -0.5rem -0.5rem;
`;

const SocialIcon = styled.a`
  color: ${$white};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  border: solid 1px ${$white};
  margin: 0.5rem;
  border-radius: 2rem;
  svg {
    height: 1rem;
    width: 1rem;
  }
`;

const MobileNavColumns = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2rem 0 1.5rem 0;
`;

const Logos = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const Lockup = styled.a`
  display: inline-flex;
  img {
    height: 2rem;
  }
`;

const Footer = () => {
  const { authenticated } = useSelector((state) => state.user);
  return (
    <UserTheme>
      <Background>
        <Wrapper>
          <div>
            <Logos>
              <Logo to="/" />
              <Lockup href="https://trillerinc.com/" target="_blank" rel="noreferrer">
                <img src={footerLockup} alt="Thuzio - a Triller Company" />
              </Lockup>
            </Logos>
            <DesktopFooter>
              <div>
                <Address>
                  <div>114 W 26th Street, 5th Floor</div>
                  <div>New York, NY 10001</div>
                  <div>
                    <EmailLink href="mailto:hello@thuzio.com">
                      hello@thuzio.com
                    </EmailLink>
                  </div>
                </Address>
                <SocialRow>
                  <SocialIcon href="https://twitter.com/Thuzio" target="_blank">
                    <FiTwitter />
                  </SocialIcon>
                  <SocialIcon href="https://www.instagram.com/thuzio/" target="_blank">
                    <FiInstagram />
                  </SocialIcon>
                  <SocialIcon href="https://www.facebook.com/thuzio/" target="_blank">
                    <FiFacebook />
                  </SocialIcon>
                  <SocialIcon href="https://www.youtube.com/c/thuzio" target="_blank">
                    <FiYoutube />
                  </SocialIcon>
                  <SocialIcon href="https://www.linkedin.com/company/thuzio/" target="_blank">
                    <FiLinkedin />
                  </SocialIcon>
                </SocialRow>
              </div>
              {
                  authenticated ? (
                    <>
                      <NavColumn>
                        <Link to="/custom">Custom Events</Link>
                        <Link to="/premium-hospitality">Premium Hospitality</Link>
                        <Link to="/our-work">Our Work</Link>
                        <Link to="/privacy">Privacy</Link>
                      </NavColumn>
                      <NavColumn>
                        <Link to="/my-events">My Events</Link>
                        <Link to="/profile">Profile</Link>
                        <Link to="/wallet">Wallet</Link>
                      </NavColumn>
                    </>
                  ) : (
                    <NavColumn>
                      <Link to="/custom">Custom Events</Link>
                      <Link to="/premium-hospitality">Premium Hospitality</Link>
                      <Link to="/our-work">Our Work</Link>
                      <Link to="/login">Corporate Accounts</Link>
                      <Link to="/privacy">Privacy</Link>
                    </NavColumn>
                  )
                }
            </DesktopFooter>
            <MobileFooter>
              <div>
                <Address>
                  <div>114 W 26th Street, 5th Floor</div>
                  <div>New York, NY 10001</div>
                  <div>
                    <EmailLink href="mailto:hello@thuzio.com">
                      hello@thuzio.com
                    </EmailLink>
                  </div>
                </Address>
              </div>
              {
                  authenticated ? (
                    <MobileNavColumns>
                      <NavColumn>
                        <Link to="/custom">Custom Events</Link>
                        <Link to="/premium-hospitality">Premium Hospitality</Link>
                        <Link to="/our-work">Our Work</Link>
                        <Link to="/privacy">Privacy</Link>
                      </NavColumn>
                      <NavColumn>
                        <Link to="/my-events">My Events</Link>
                        <Link to="/profile">Profile</Link>
                        <Link to="/wallet">Wallet</Link>
                      </NavColumn>
                    </MobileNavColumns>
                  ) : (
                    <MobileNavColumns>
                      <NavColumn>
                        <Link to="/custom">Custom Events</Link>
                        <Link to="/premium-hospitality">Premium Hospitality</Link>
                        <Link to="/our-work">Our Work</Link>
                        <Link to="/login">Members</Link>
                        <Link to="/privacy">Privacy</Link>
                      </NavColumn>
                    </MobileNavColumns>
                  )
                }
              <SocialRow>
                <SocialIcon href="https://twitter.com/Thuzio" target="_blank">
                  <FiTwitter />
                </SocialIcon>
                <SocialIcon href="https://www.instagram.com/thuzio/" target="_blank">
                  <FiInstagram />
                </SocialIcon>
                <SocialIcon href="https://www.facebook.com/thuzio/" target="_blank">
                  <FiFacebook />
                </SocialIcon>
                <SocialIcon href="https://www.youtube.com/c/thuzio" target="_blank">
                  <FiYoutube />
                </SocialIcon>
                <SocialIcon href="https://www.linkedin.com/company/thuzio/" target="_blank">
                  <FiLinkedin />
                </SocialIcon>
              </SocialRow>
            </MobileFooter>
          </div>
        </Wrapper>
      </Background>
    </UserTheme>
  );
};

export default Footer;
