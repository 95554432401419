import { gql, useMutation } from '@apollo/client';
import _omit from 'lodash/omit';
import { formatPhoneNumber } from 'react-phone-number-input';
import { GqlError, formatErrors } from '../utils/errors';

const DEFAULT_CREATE_REQUEST_MUTATION = gql`
  mutation createRequestMutation(
    $email: String
    $phoneNumber: String
    $criteria: JSON!
    $leadCaptureFormId: Int
    $ticketedEventId: Int
    $viaUrl: String!
    $viaCompany: String!
    $type: String!
  ) {
    newRequest(
      email: $email
      phoneNumber: $phoneNumber
      criteria: $criteria
      leadCaptureFormId: $leadCaptureFormId
      ticketedEventId: $ticketedEventId
      viaUrl: $viaUrl
      viaCompany: $viaCompany
      type: $type
    ) {
      id
      email
      phoneNumber
      ticketedEventByTicketedEventId {
        id
        name
        utcDate
        timezone
      }
      criteria
      type
    }
  }
`;

const useCreateRequest = (onCompleted, mutation) => {
  const [createRequestMutation, { loading, data, error }] = useMutation(
    mutation || DEFAULT_CREATE_REQUEST_MUTATION,
    { onCompleted },
  );

  const createRequest = async (params) => {
    try {
      // Remove contact details from criteria and attach via its own keys
      const {
        fields,
        leadCaptureFormId,
        viaUrl,
        viaCompany,
        type,
      } = params;

      const criteriaOnly = _omit(fields, ['email', 'phoneNumber', 'eventId']);
      const criteria = JSON.stringify(criteriaOnly);

      const {
        email,
        phoneNumber,
        eventId,
      } = fields;

      const response = await createRequestMutation({
        variables: {
          email,
          phoneNumber: phoneNumber ? formatPhoneNumber(phoneNumber) : null,
          ticketedEventId: eventId ? +eventId : null,
          criteria,
          type,
          leadCaptureFormId,
          viaUrl,
          viaCompany,
        },
      });
      return response.data.newRequest;
    } catch (e) {
      const formattedErrors = formatErrors(e);
      throw new GqlError({
        name: 'Request Creation Error',
        errors: formattedErrors,
      });
    }
  };

  return [
    createRequest,
    {
      loading,
      data,
      error: error ? formatErrors(error) : null,
    },
  ];
};

export default useCreateRequest;
