import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const DEFAULT_TITLE = 'Thuzio';
const DEFAULT_DESCRIPTION = "Thuzio works with the world's leading brands to create unforgettable events & experiences.";

/*
* Titles and descriptions set within a component will
* override those set here (e.g. titles dependent on an API call)
*/

const Head = (props) => {
  const {
    title,
    description,
    script,
  } = props;
  return (
    <Helmet>
      <title>{ title ? `${title} | Thuzio` : DEFAULT_TITLE }</title>
      <meta name="description" content={description || DEFAULT_DESCRIPTION} />
      {
        script && <script>{ `${script}` }</script>
      }
    </Helmet>
  );
};

Head.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  script: PropTypes.string,
};

Head.defaultProps = {
  title: '',
  description: '',
  script: '',
};

export default Head;
