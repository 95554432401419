import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import {
  styleTheme,
  $primaryFont,
  $medium,
} from '../../styles';

const applyBaseStyle = (props) => {
  const {
    disabled,
    margin,
    padding,
    fullWidth,
    theme,
  } = props;
  return `
    font-family: ${$primaryFont};
    width: ${(fullWidth ? '100%' : 'initial')};
    box-sizing: border-box;
    cursor: ${(disabled ? 'not-allowed' : 'pointer')};
    min-width: 6rem;
    min-height: 2rem;
    background: ${styleTheme[theme].background};
    color: ${styleTheme[theme].color} !important;
    text-align: center;
    border-radius: 0.25rem;
    padding: ${padding || '0.75rem 1rem'};
    font-weight: ${$medium};
    line-height: 1rem;
    font-size: 1rem;
    opacity: ${(disabled ? '0.65' : 1)};
    border: solid 2px ${styleTheme[theme].borderColor || styleTheme[theme].background};
    ${!disabled && `
      &:hover {
        background: ${styleTheme[theme].hoverBackground || styleTheme[theme].background};
        color: ${styleTheme[theme].color} !important;
        border: solid 2px ${styleTheme[theme].borderColor || styleTheme[theme].hoverBackground};
      }
    `}
    transition: all 0.25s;
    outline: none;
    margin: ${margin || ''};
    white-space: nowrap;
  `;
};

const applyInlineFlexStyle = () => `
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled.button(applyBaseStyle);

// Known styled-components bug: https://github.com/styled-components/styled-components/issues/2131
const StyledLink = styled(
  ({
    className, disabled, to, href, onClick, margin, children,
  }) => (
    <Link
      className={className}
      disabled={disabled}
      to={to}
      href={href}
      onClick={onClick}
      margin={margin}
    >
      {children}
    </Link>
  ),
)`
  ${applyBaseStyle}  
  ${applyInlineFlexStyle}
`;

const StyledAnchor = styled.a`
  ${applyBaseStyle}  
  ${applyInlineFlexStyle}
`;

const Button = (props) => {
  const {
    to,
    href,
    children,
    disabled,
    className,
    onClick,
    margin,
    padding,
    fullWidth,
    theme,
    target,
  } = props;
  let ButtonElement = StyledButton;
  if (to) {
    ButtonElement = StyledLink;
  } else if (href) {
    ButtonElement = StyledAnchor;
  }

  return (
    <ButtonElement
      className={className}
      disabled={disabled}
      to={to}
      href={href}
      onClick={onClick}
      margin={margin}
      padding={padding}
      fullWidth={fullWidth}
      theme={theme}
      target={target}
    >
      { children }
    </ButtonElement>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  to: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  margin: PropTypes.string,
  padding: PropTypes.string,
  theme: PropTypes.string,
  target: PropTypes.string,
};

Button.defaultProps = {
  disabled: false,
  to: null,
  href: null,
  className: null,
  onClick: null,
  margin: null,
  padding: null,
  fullWidth: false,
  theme: 'gold',
  target: null,
};

export default Button;
