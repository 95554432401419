import {
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import userReducer from './user/userSlice';
import appReducer from './app/appSlice';
import longLeadCaptureFormReducer from './longLeadCaptureForm/longLeadCaptureFormSlice';

// https://redux-toolkit.js.org/tutorials/advanced-tutorial

export default configureStore({
  middleware: [...getDefaultMiddleware()],
  reducer: combineReducers({
    user: userReducer,
    app: appReducer,
    longLeadCaptureForm: longLeadCaptureFormReducer,
  }),
});
