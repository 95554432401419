import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FiArrowRightCircle } from 'react-icons/fi';
import styled from 'styled-components';
import { formatNum } from '../../utils/currency';

import {
  $black,
  $copyBlack,
  $medium,
  $robinBlue,
  $lightRobinBlue,
  $red,
  $white,
} from '../../styles';

const Container = styled.div`
  display: flex;
  align-items: center;
  background: ${$lightRobinBlue};
  padding: 1rem 1.25rem;
  border-radius: 0.5rem;
  justify-content: space-between;
`;

const CreditsIcon = styled(FiArrowRightCircle)`
  height: 2rem;
  width: 2rem;
  color: ${$robinBlue};
`;

const Balance = styled.div`
  > div:first-child {
    font-size: 0.875rem;
    color: ${$robinBlue};
    margin-bottom: 0.5rem;
    font-weight: ${$medium};
  }
  > div:nth-child(2) {
    font-size: 0.75rem;
    color: ${$copyBlack};
  }
  > div:last-child {
    font-size: 1.5rem;
    color: ${$black}
  }
  padding-right: 1rem;
`;

const InsufficientCredits = styled.div`
  background: ${$white};
  color: ${$red};
  font-weight: ${$medium};
  padding: 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.75rem;
`;

const WalletBalance = (props) => {
  const {
    className,
    to,
    href,
    insufficientCredits,
  } = props;
  const { user } = useSelector((state) => state.user);

  let cta;

  if (insufficientCredits) {
    cta = (
      <Link to={to || '/wallet'}>
        <InsufficientCredits>
          Insufficient Credits
        </InsufficientCredits>
      </Link>
    );
  } else if (href) {
    cta = (
      <a href={href} target="_blank" rel="noreferrer">
        <CreditsIcon />
      </a>
    );
  } else {
    cta = (
      <Link to={to || '/wallet'}>
        <CreditsIcon />
      </Link>
    );
  }
  return (
    <Container className={className}>
      <Balance>
        <div>Wallet</div>
        <div>Credit balance</div>
        <div>{ formatNum(user.currentBalance) }</div>
      </Balance>
      { cta }
    </Container>
  );
};

WalletBalance.propTypes = {
  className: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.string,
  insufficientCredits: PropTypes.bool,
};

WalletBalance.defaultProps = {
  className: '',
  href: null,
  to: null,
  insufficientCredits: false,
};

export default WalletBalance;
