import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import _dropRightWhile from 'lodash/dropRightWhile';
import _dropWhile from 'lodash/dropWhile';
import draftToHtml from 'draftjs-to-html';
import _find from 'lodash/find';
import _camelCase from 'lodash/camelCase';
import _mapKeys from 'lodash/mapKeys';
import _mapValues from 'lodash/mapValues';
import DOMPurify from 'dompurify';
import { eventImagePlaceholder } from '../imageUrls';

const trimDraftBlocks = (blocks) => {
  // Get rid of empty blocks (i.e. new lines at the start/end of the description)
  const blocksLeftTrimmed = _dropWhile(blocks, ({ text }) => text.trim().length === 0);
  const blocksTrimmed = _dropRightWhile(blocksLeftTrimmed, ({ text }) => text.trim().length === 0);
  return blocksTrimmed;
};

// Convert editor data to raw and HTML formats to persist in db
export const convertRichTextToSchema = (editorData) => {
  const contentState = editorData.getCurrentContent();
  const raw = convertToRaw(contentState);
  const { blocks } = raw;
  const blocksTrimmed = trimDraftBlocks(blocks);

  if (blocksTrimmed.length === 0) {
    return {
      raw: null,
      html: null,
    };
  }

  const trimmed = {
    ...raw,
    blocks: blocksTrimmed,
  };

  // Convert to html tags
  let html = draftToHtml(trimmed);

  // Draft only lets us define inline styles in pxs, manually replace with REMs for responsiveness
  if (html) {
    html = html.replace(/font-size: 16px;/g, 'font-size: 1rem;');
    html = html.replace(/font-size: 24px;/g, 'font-size: 1.5rem;');
    html = html.replace(/font-size: 32px;/g, 'font-size: 2rem;');
  }

  let stub;

  // Assume the second block is the first paragraph - should avoid the first heading (e.g. 'About')
  const firstParagraph = _find(
    blocksTrimmed,
    ({ text }, i) => i > 0 && text && text.trim().length > 0,
  );
  if (firstParagraph) {
    stub = firstParagraph.text;
  }

  return {
    raw: JSON.stringify(trimmed),
    html,
    stub,
  };
};

// Convert editor data from raw to draftjs editor content type
export const convertFromSchemaToRichText = (raw) => {
  const json = JSON.parse(raw);
  const content = convertFromRaw(json);
  return EditorState.createWithContent(content);
};

DOMPurify.addHook('afterSanitizeAttributes', (node) => {
  // set all elements owning target to target=_blank
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener');
  }
});

export const createHtmlMarkup = (html) => ({
  __html: DOMPurify.sanitize(html),
});

export const getEventImage = (event, orientation = 'portrait') => {
  const {
    headshotThumbnailUrl,
    headshotImageUrl,
    thumbnailUrl,
    coverImageUrl,
  } = event;
  let image;
  if (orientation === 'portrait') {
    image =
      headshotThumbnailUrl ||
      headshotImageUrl ||
      thumbnailUrl ||
      coverImageUrl ||
      eventImagePlaceholder;
  } else {
    image =
      thumbnailUrl ||
      coverImageUrl ||
      headshotThumbnailUrl ||
      headshotImageUrl ||
      eventImagePlaceholder;
  }
  return image;
};

// Remove unprintable/invisible unicode characters
export const sanitizeUrl = (url) => url.replace(/[^ -~]+/g, '');

export const camelKeys = (obj) => obj && _mapKeys(obj, (value, key) => _camelCase(key));

export const numberOnlyNormalizer = (value) => {
  if (value) {
    return value.replace(/[^0-9]+/g, '');
  }
  return value;
};

// Antd upload inputs only accept arrays
export const convertImageUrlToAntdInput = (image) => ({
  uid: image,
  name: image,
  status: 'done',
  url: image,
});

// Antd upload inputs only accept arrays
export const convertAntdInputToImageUrl = (file) => file.url;

export const trimValues = (obj) => _mapValues(obj, (val) => {
  const isString = typeof val === 'string';
  if (!isString) {
    return val;
  }
  return val.trim();
});
