import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { get, post } from '../../utils/backend';
import { setLoading } from '../app/appSlice';

// TODO: use createAsyncThunk

const INITIAL_USER_STATE = {
  user: {
    id: null,
    givenNames: null,
    familyName: null,
    email: null,
    companyName: null,
    jobTitle: null,
    isAdmin: false,
    authenticated: false,
  },
  registrationPersonalDetails: {},
  registrationBundleId: null,
  registrationPaymentDetails: {},
  emailVerificationRequired: false,
  emailVerificationRecipient: null,
  verificationEmailHash: null,
  verificationEmailId: null,
  error: null,
  showAuthErrorModal: false,
  registrationStep: 0,
};

const getErrorMessage = (e) => {
  if (e.response) {
    return e.response.data.message;
  }
  return e.message;
};

const userSlice = createSlice({
  name: 'user',
  initialState: INITIAL_USER_STATE,
  reducers: {
    setInitializing: (state, { payload }) => {
      state.error = null;
      state.intializing = payload;
    },
    setUser: (state, { payload }) => {
      const { user, authenticated, currentBalance } = payload;
      state.initializing = false;
      state.user = user || {};
      state.authenticated = authenticated;
      state.error = null;
      state.currentBalance = currentBalance;
    },
    setError: (state, { payload }) => {
      state.initializing = false;
      state.error = payload;
    },
    setShowAuthErrorModal: (state, { payload }) => {
      state.showAuthErrorModal = payload;
    },
    setVerificationStatus: (state, { payload }) => {
      const {
        emailVerificationRequired,
        emailVerificationRecipient,
        verificationEmailHash,
        verificationEmailId,
      } = payload;
      state.emailVerificationRequired = emailVerificationRequired;
      state.emailVerificationRecipient = emailVerificationRecipient;
      state.verificationEmailHash = verificationEmailHash;
      state.verificationEmailId = verificationEmailId;
    },
    resetUser: () => INITIAL_USER_STATE,
    setRegistrationStep: (state, { payload }) => {
      state.registrationStep = payload;
    },
    setRegistrationPersonalDetails: (state, { payload }) => {
      state.registrationPersonalDetails = payload;
    },
    setRegistrationBundleId: (state, { payload }) => {
      state.registrationBundleId = payload;
    },
    setRegistrationPaymentDetails: (state, { payload }) => {
      state.registrationPaymentDetails = payload;
    },
  },
});

export default userSlice.reducer;

export const {
  setUser,
  setError,
  setShowAuthErrorModal,
  resetUser,
  setVerificationStatus,
  setRegistrationStep,
  setRegistrationPersonalDetails,
  setRegistrationBundleId,
  setRegistrationPaymentDetails,
} = userSlice.actions;

export const fetchUserData = () => async (dispatch) => {
  try {
    const userData = await get({ path: '/user' });
    dispatch(setUser(userData));
    dispatch(setLoading(false));
  } catch (e) {
    const errorMessage = getErrorMessage(e);
    toast.error(errorMessage);
    dispatch(setLoading(false));
  }
};

export const prevRegistrationStep = () => async (dispatch, getState) => {
  const { user: userState } = getState();
  if (userState.registrationStep > 0) {
    dispatch(setRegistrationStep(userState.registrationStep - 1));
    window.scrollTo(0, 0);
  }
};

export const nextRegistrationStep = () => async (dispatch, getState) => {
  const { user: userState } = getState();
  dispatch(setRegistrationStep(userState.registrationStep + 1));
  window.scrollTo(0, 0);
};

export const verifyPersonalDetails = (personalDetails) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    // Returns 'true' if the email is not in use, an error otherwise
    await get({ path: '/auth/email/check', params: { email: personalDetails.email } });
    dispatch(setError(null));
    dispatch(nextRegistrationStep());
    dispatch(setRegistrationPersonalDetails(personalDetails));
    dispatch(setLoading(false));
  } catch (e) {
    const errorMessage = getErrorMessage(e);
    dispatch(setError(errorMessage));
    dispatch(setLoading(false));
    dispatch(setShowAuthErrorModal(true));
  }
};

export const registerUser = () => async (dispatch, getState) => {
  try {
    dispatch(setLoading(true));

    const { user: userState } = getState();
    const {
      registrationPersonalDetails,
      registrationBundleId,
      registrationPaymentDetails,
    } = userState;

    const {
      email,
      password,
      givenNames,
      familyName,
      companyName,
      jobTitle,
    } = registrationPersonalDetails;

    const newUserData = await post({
      path: '/auth/email/register',
      body: {
        email,
        password,
        givenNames,
        familyName,
        companyName,
        jobTitle,
        customerId: registrationPaymentDetails.customerId,
        bundleId: registrationBundleId,
      },
    });

    if (newUserData.emailVerificationRequired) {
      dispatch(setVerificationStatus({
        emailVerificationRequired: true,
        emailVerificationRecipient: newUserData.emailVerificationRecipient,
        verificationEmailHash: newUserData.emailHash,
        verificationEmailId: newUserData.emailId,
      }));
      dispatch(nextRegistrationStep());
    } else {
      await dispatch(fetchUserData());
    }

    dispatch(setLoading(false));
  } catch (e) {
    const errorMessage = getErrorMessage(e);
    dispatch(setError(errorMessage));
    dispatch(setLoading(false));
    dispatch(setShowAuthErrorModal(true));
  }
};

export const loginUser = (loginData, referrer) => async (dispatch) => {
  try {
    const { email, password } = loginData;
    dispatch(setLoading(true));
    await post({
      path: '/auth/email/login',
      body: {
        email,
        password,
      },
    });
    if (referrer) {
      window.location.assign(`${process.env.REACT_APP_FRONTEND_URL}${referrer}`);
    } else {
      await dispatch(fetchUserData());
      dispatch(setLoading(false));
    }
  } catch (e) {
    const errorMessage = getErrorMessage(e);
    dispatch(setError(errorMessage));
    dispatch(setLoading(false));
  }
};

export const resetPassword = (resetPasswordData) => async (dispatch) => {
  try {
    const {
      email,
      token,
      newPassword,
      isActivation,
    } = resetPasswordData;
    dispatch(setLoading(true));
    const userData = await post({
      path: '/auth/email/reset',
      body: {
        email,
        token,
        newPassword,
      },
    });
    dispatch(setUser(userData));
    dispatch(setLoading(false));
    if (isActivation) {
      toast.success('Your account has been activated');
    } else {
      toast.success('Your password has been set');
    }
  } catch (e) {
    const errorMessage = getErrorMessage(e);
    dispatch(setError(errorMessage));
    dispatch(setLoading(false));
  }
};

// TODO: Find a better place for this assume user control logic

export const assumeUser = (userId) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    await post({ path: `/auth/assume/${userId}` });
    window.location.assign(`${process.env.REACT_APP_FRONTEND_URL}`);
  } catch (e) {
    const errorMessage = getErrorMessage(e);
    toast.error(errorMessage);
    dispatch(setLoading(false));
  }
};

export const exitAssumeMode = () => async (dispatch) => {
  try {
    await post({ path: '/auth/assume/end' });
    // force reload of the entire application
    window.location.assign(`${process.env.REACT_APP_FRONTEND_URL}/admin/users`);
  } catch (e) {
    const errorMessage = getErrorMessage(e);
    toast.error(errorMessage);
    dispatch(setLoading(false));
  }
};
